import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../style.css';
import { UserContext } from 'contexts/userContext';
import { Checkbox } from '@mui/material';
// import { UserContext, UserContextState } from "contextApis/userContext";

interface DeviceAssignmentFormProps {
  pName?: string;
  pDob?: string | undefined;
  sign?: string | undefined;
  // relative: string | null;
  pEmail?: string | undefined;
  signer?: string | null;
  clientLogo?: string | null;
  consentName?: string | null;
  orgName?: string | null;
  deviceId?: string | null;
  userInitial?: string;
  selectedUser?: any;
  checkBoxValue?: any;
  setAckPatientName?: (flag: any) => void;
  setImei?: (flag: string) => void;
  setTime?: (flag: string) => void;
  setIsPatientEducation?: (flag: boolean) => void;
  isPatientEducation?: boolean;
}

const DeviceAssignmentForm: React.FC<DeviceAssignmentFormProps> = ({
  pName,
  pDob,
  sign,
  // relative,
  pEmail,
  signer,
  clientLogo,
  consentName,
  orgName,
  deviceId,
  userInitial,
  selectedUser,
  checkBoxValue,
  setAckPatientName,
  setImei,
  setTime,
  setIsPatientEducation,
  isPatientEducation,
}) => {
  //   const userContext = useContext<UserContxtState>(UserCeontext);

  const { userData } = useContext(UserContext);
  const OrgID = userData?.userOrg;
  const OrgId: string =
    OrgID !== undefined ? OrgID.toString() : 'default value';
  const [patientInitial, setPatientInitial] = useState(userInitial);
  const [clientLogoCaregem, setClientLogoCaregem] = useState(
    `${process.env['REACT_APP_ASSET_ENDPOINT']}logo/${OrgId}/organisation_logo.jpg`,
  );
  const [practiceLogoCaregem, setPracticeLogoCaregem] = useState(
    `https://caregem-docs-us-east-1-654654614422.s3.amazonaws.com/logo/CareGemHealthLogo.png`,
  );

  const date = new Date();

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const formattedTodaysDate = `${month}/${day}/${year}`;

  const time = useMemo(() => {
    return date.toLocaleTimeString();
  }, []);

  useEffect(() => {
    setImei && setImei(selectedUser?.imei);
    setTime && setTime(time);
  }, [selectedUser, time]);

  const patientEducationCheck = () => {
    setIsPatientEducation?.(!isPatientEducation);
  };

  return (
    <div
      className="form-container"
      style={{ marginTop: 40, marginBottom: -80, width: '90%' }}
    >
      <div className="header">
        <img
          className="logo-left"
          src={clientLogoCaregem}
          alt="NephrologySpeaciallistsLogo"
          width={300}
        />
        <img
          className="logo-right"
          src={practiceLogoCaregem}
          alt="CareGemHealthLogo"
          width={180}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <p style={{ fontWeight: 650, fontSize: 18, marginBottom: 20 }}>
          RPM Device Acknowledgment
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ width: '50%' }}>
            Patient Name: <b>{selectedUser.name.toUpperCase()}</b>
          </p>
          <p style={{ width: '50%' }}>
            Device ID: <b>{selectedUser?.ack_imei}</b>
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: 10,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              marginBottom: 30,
            }}
          >
            <span>
              Acknowledgment Taken By:{' '}
              <b>{sessionStorage.getItem('loggedInUser')}</b>
            </span>
            <div
              style={{
                display: 'flex',
                gap: 60,
                width: '100%',
              }}
            >
              <span>
                Date: <b>{formattedTodaysDate}</b>
              </span>
              <span>
                Time: <b>{time}</b>
              </span>
            </div>
          </div>
          <div>
            Acknowledgement Provided By (Full Name)&nbsp;:
            <input
              type="text"
              placeholder="Type here..."
              className="input-box"
              onChange={(e: any) =>
                setAckPatientName && setAckPatientName(e.target.value)
              }
              style={{ marginLeft: 20 }}
            />
          </div>

          <div>
            (Relationship to person acknowledging receipt)&nbsp;:
            <span
              style={{
                marginLeft: 18,
                borderBottom: '1px solid black',
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              {checkBoxValue}
            </span>
          </div>
          <div>
            <Checkbox
              size="small"
              color="success"
              checked={isPatientEducation}
              onChange={() => {
                patientEducationCheck();
              }}
              style={{ marginTop: -2 }}
            />
            I confirm that Patient/Family/Caregiver was educated and questions
            answered on :&nbsp;
            <ul>
              <li>
                Use of RPM device{' '}
                {/* <Checkbox
                  size="small"
                  color="success"
                  checked={isPatientEducation}
                />{' '} */}
              </li>
              <li>
                Maintenance of device{' '}
                {/* <Checkbox
                  size="small"
                  color="success"
                  checked={isPatientEducation}
                /> */}
              </li>
              <li>
                The correct method of collection of readings{' '}
                {/* <Checkbox
                  size="small"
                  color="success"
                  checked={isPatientEducation}
                /> */}
              </li>
              <li>
                The number of readings required for accurate monitoring{' '}
                {/* <Checkbox
                  size="small"
                  color="success"
                  checked={isPatientEducation}
                /> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceAssignmentForm;
