import React from 'react';
import { RemoteMonitoringPatientDetails } from 'api/SharedTypes';
import { parseInt } from 'lodash';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { DateTime } from 'luxon';
interface RemoteMonitoringDataProps {
  months: string[];
  patientDetails: RemoteMonitoringPatientDetails[];
}

const DATE_OF_SERVICE_FORMAT = 'EEE, dd MMM yyyy hh:mm:ss';

const RemoteMonitoringData: React.FC<RemoteMonitoringDataProps> = ({
  months,
  patientDetails,
}) => {
  const convertMonthNameToNumber = (monthName: string) => {
    let myDate = new Date(monthName + ' 1, 2021');
    let monthDigit = myDate.getMonth();
    return isNaN(monthDigit) ? 0 : monthDigit + 1;
  };

  const convertFormat = (date: string) => {
    return DateTime.fromISO(date, {
      zone: 'UTC',
    })
      .toLocal()
      .toFormat('MM/dd/yyyy');
  };

  const intl = useIntl();

  const formattedTime = (minutes: any) => {
    const fullMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - fullMinutes) * 60);
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${fullMinutes}:${formattedSeconds}`;
  };

  return (
    <tbody id="remoteMonitorReport">
      {patientDetails
        ?.sort((patient1, patient2) => {
          return patient1?.patient_last_name?.localeCompare(
            patient2?.patient_last_name,
          );
        })
        ?.map(eachPatient => {
          let provider_for_99453 = eachPatient.creator_of_99453
            ? `${eachPatient?.creator_of_99453
                ?.split(' ')[0]
                ?.substr(0, 1)}${eachPatient.creator_of_99453
                ?.split(' ')[1]
                ?.substr(0, 2)}`
            : '';
          return (
            eachPatient.paired_device_details?.length !== 0 && (
              <>
                <tr className="rmReportRowBackground">
                  <td>{`${eachPatient.patient_name}`}</td>
                  <td>
                    {eachPatient.all_provider_name
                      ?.split(',')
                      ?.sort((a, b) => {
                        return a.split(' ')[1].localeCompare(b?.split(' ')[1]);
                      })
                      ?.map(item => {
                        return <li className="prvNameListItem">{item}</li>;
                      })}
                  </td>
                  <td>
                    {eachPatient.paired_device_details?.map(
                      ({ imei, start_date, end_date }) => {
                        return (
                          <ul
                            className="deviceDetailsText"
                            key={imei + start_date}
                          >
                            <li>
                              {intl.formatMessage({
                                id: I18nKey.REMOTE_MONITORING_DEVICE_ID,
                              })}{' '}
                              {imei}
                            </li>
                            <li>
                              {`${intl.formatMessage({
                                id: I18nKey.USER_FORM_FIELD_START_DATE,
                              })}: `}
                              {convertFormat(start_date)}
                            </li>
                            <li>
                              {`${intl.formatMessage({
                                id: I18nKey.USER_FORM_FIELD_END_DATE,
                              })}: `}
                              {end_date && convertFormat(end_date)}
                            </li>
                          </ul>
                        );
                      },
                    )}
                  </td>
                  <td colSpan={months.length}></td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    {intl.formatMessage({
                      id: I18nKey.REMOTE_MONITORING_MINUTES_OF_TALKTIME,
                    })}
                  </td>
                  {months?.map(eachMonth => {
                    let minutes = eachPatient.call_records
                      ? Object.keys(eachPatient.call_records)?.find(
                          key =>
                            key ===
                            convertMonthNameToNumber(eachMonth).toString(),
                        )
                      : undefined;
                    if (minutes !== undefined) {
                      return (
                        <td>
                          {formattedTime(
                            eachPatient.call_records[minutes] / 60,
                          )}{' '}
                          min
                        </td>
                      );
                    } else
                      return (
                        <td>
                          {intl.formatMessage({
                            id: I18nKey.REMOTE_MONITORING_ZERO_MINUTES_TALKTIME,
                          })}
                        </td>
                      );
                  })}
                </tr>
                <tr>
                  <td colSpan={3}>
                    {intl.formatMessage({
                      id: I18nKey.REMOTE_MONITORING_DAYS_OF_RECORDING,
                    })}
                  </td>
                  {months.map(eachMonth => {
                    let daysRecording = eachPatient.days_recording
                      ? eachPatient.days_recording?.find(
                          record =>
                            DateTime.fromISO(record.end_date, {
                              zone: 'UTC',
                            })
                              .toLocal()
                              .toFormat('MMM')
                              .toLocaleLowerCase() === eachMonth.toLowerCase(),
                        )
                      : undefined;
                    if (daysRecording !== undefined) {
                      return (
                        <td>
                          {`${daysRecording.number_of_days}     `}
                          <span className="blueText">
                            <b>
                              <i>
                                {DateTime.fromISO(daysRecording.end_date, {
                                  zone: 'UTC',
                                })
                                  .toLocal()
                                  .toFormat('MMM/dd/yyyy')}
                              </i>
                            </b>
                          </span>
                        </td>
                      );
                    } else return <td></td>;
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td>{'99453'}</td>
                  {eachPatient.date_of_service_99453 !== null ? (
                    <td>
                      {`Y-`}
                      <span className="blueText">
                        <b>
                          {provider_for_99453}
                          {'-'}
                          {eachPatient.date_of_service_99453 &&
                            DateTime.fromISO(
                              eachPatient.date_of_service_99453,
                              { zone: 'UTC' },
                            ).toFormat('MMM/dd/yyyy')}
                        </b>
                      </span>
                    </td>
                  ) : (
                    [...Array(5)].map((e, i) => {
                      return <td key={i}>&nbsp;</td>;
                    })
                  )}
                  {eachPatient.date_of_service_99453 !== null
                    ? [...Array(4)].map((e, i) => {
                        return <td key={i}>&nbsp;</td>;
                      })
                    : ``}
                </tr>
                <tr>
                  <td></td>
                  <td>{'99454'}</td>
                  <td></td>
                  {months.map(eachMonth => {
                    let getDays = eachPatient.days_recording
                      ? eachPatient.days_recording?.find(
                          item =>
                            DateTime.fromFormat(
                              item.end_date,
                              DATE_OF_SERVICE_FORMAT,
                              { zone: 'UTC' },
                            )
                              .toLocal()
                              .toFormat('MMM')
                              .toLocaleLowerCase() === eachMonth.toLowerCase(),
                        )
                      : undefined;
                    let patient_99454_bills =
                      eachPatient.billing_details?.find(
                        item => item.billing_charge_code === '99454',
                      ) !== undefined
                        ? eachPatient.billing_details.filter(
                            item => item.billing_charge_code === '99454',
                          )
                        : [];
                    if (patient_99454_bills.length !== 0) {
                      let cur_month_bill = patient_99454_bills.filter(
                        item =>
                          DateTime.fromFormat(
                            item.date_of_service,
                            DATE_OF_SERVICE_FORMAT,
                            { zone: 'UTC' },
                          )
                            .toLocal()
                            .toFormat('MMM')
                            .toLowerCase() === eachMonth.toLowerCase(),
                      );
                      if (cur_month_bill !== undefined) {
                        return (
                          <td>
                            {cur_month_bill.map(item => (
                              <>
                                {`Y-`}
                                <span className="blueText">
                                  <b>
                                    {`${item['provider_name']
                                      .split(' ')[0]
                                      .substr(0, 1)}${item['provider_name']
                                      .split(' ')[1]
                                      .substr(0, 2)}`}
                                    {'-'}
                                    {DateTime.fromFormat(
                                      item.date_of_service,
                                      DATE_OF_SERVICE_FORMAT,
                                      { zone: 'UTC' },
                                    ).toFormat('MMM/dd/yyyy')}
                                  </b>
                                </span>
                                <br />
                              </>
                            ))}
                          </td>
                        );
                      } else return <td></td>;
                    } else if (
                      getDays !== undefined &&
                      getDays.number_of_days > 16
                    ) {
                      return (
                        <td>
                          <span className="redText">?</span>
                        </td>
                      );
                    } else return <td></td>;
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td>{'99457'}</td>
                  <td></td>
                  {months.map(eachMonth => {
                    let getCallRecords = eachPatient.call_records
                      ? Object.keys(eachPatient.call_records)?.find(
                          key =>
                            key ===
                            convertMonthNameToNumber(eachMonth).toString(),
                        )
                      : undefined;
                    let patient_99457_bills =
                      eachPatient.billing_details?.find(
                        item => item.billing_charge_code === '99457',
                      ) !== undefined
                        ? eachPatient.billing_details.filter(
                            item => item.billing_charge_code === '99457',
                          )
                        : [];
                    if (patient_99457_bills.length !== 0) {
                      let cur_month_bill = patient_99457_bills.filter(
                        item =>
                          DateTime.fromFormat(
                            item.date_of_service,
                            DATE_OF_SERVICE_FORMAT,
                            { zone: 'UTC' },
                          )
                            .toLocal()
                            .toFormat('MMM')
                            .toLowerCase() === eachMonth.toLowerCase(),
                      );
                      if (cur_month_bill !== undefined) {
                        return (
                          <td>
                            {cur_month_bill.map(item => (
                              <>
                                {`Y-`}
                                <span className="blueText">
                                  <b>
                                    {`${item['provider_name']
                                      .split(' ')[0]
                                      .substr(0, 1)}${item['provider_name']
                                      .split(' ')[1]
                                      .substr(0, 2)}`}
                                    {'-'}
                                    {DateTime.fromFormat(
                                      item['date_of_service'],
                                      DATE_OF_SERVICE_FORMAT,
                                      {
                                        zone: 'UTC',
                                      },
                                    ).toFormat('MMM/dd/yyyy')}
                                  </b>
                                </span>
                                <br />
                              </>
                            ))}
                          </td>
                        );
                      } else return <td></td>;
                    } else if (
                      getCallRecords !== undefined &&
                      parseInt(
                        (eachPatient.call_records[getCallRecords] / 60).toFixed(
                          2,
                        ),
                      ) > 20
                    ) {
                      return (
                        <td>
                          <span className="redText">?</span>
                        </td>
                      );
                    } else return <td></td>;
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td>{'99458'}</td>
                  <td></td>
                  {months.map(eachMonth => {
                    let getCallRecords = eachPatient.call_records
                      ? Object.keys(eachPatient.call_records)?.find(
                          key =>
                            key ===
                            convertMonthNameToNumber(eachMonth).toString(),
                        )
                      : undefined;
                    let patient_99458_bills =
                      eachPatient.billing_details?.find(
                        item => item.billing_charge_code === '99458',
                      ) !== undefined
                        ? eachPatient.billing_details.filter(
                            item => item.billing_charge_code === '99458',
                          )
                        : [];

                    if (patient_99458_bills.length !== 0) {
                      let cur_month_bill = patient_99458_bills.filter(
                        item =>
                          DateTime.fromFormat(
                            item.date_of_service,
                            DATE_OF_SERVICE_FORMAT,
                            { zone: 'UTC' },
                          )
                            .toLocal()
                            .toFormat('MMM')
                            .toLowerCase() === eachMonth.toLowerCase(),
                      );

                      if (cur_month_bill !== undefined) {
                        return (
                          <td>
                            {cur_month_bill.map(item => (
                              <>
                                {`Y-`}
                                <span className="blueText">
                                  <b>
                                    {`${item['provider_name']
                                      .split(' ')[0]
                                      .substr(0, 1)}${item['provider_name']
                                      .split(' ')[1]
                                      .substr(0, 2)}`}
                                    {'-'}
                                    {DateTime.fromFormat(
                                      item.date_of_service,
                                      DATE_OF_SERVICE_FORMAT,
                                      { zone: 'UTC' },
                                    ).toFormat('MMM/dd/yyyy')}
                                  </b>
                                </span>
                                <br />
                              </>
                            ))}
                          </td>
                        );
                      } else return <td></td>;
                    } else if (
                      getCallRecords !== undefined &&
                      parseInt(
                        (
                          eachPatient.call_records[getCallRecords] / 60
                        )?.toFixed(2),
                      ) > 40
                    ) {
                      return (
                        <td>
                          <span className="redText">?</span>
                        </td>
                      );
                    } else return <td></td>;
                  })}
                </tr>
              </>
            )
          );
        })}
    </tbody>
  );
};

export default RemoteMonitoringData;
