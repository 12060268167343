import React, { useEffect, useMemo } from 'react';
import { createApiClient } from 'api/apiClient';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { useParams } from 'react-router';
import { UserRole } from 'services/Utils';
import './CallRecords.css';
import { CircularProgress, Alert, AlertTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { useQueries } from 'react-query';
import { DateTime } from 'luxon';

const convertUTCDateToLocal = (dateString: string) => {
  const utcDate = DateTime.fromFormat(dateString, 'MMMM dd, yyyy hh:mm a', {
    zone: 'UTC',
  });
  const localDate = utcDate.toLocal();
  return localDate.toFormat('MMMM dd, yyyy hh:mm a');
};

const CallRecords: React.FC = () => {
  const { internalId, userId } = useParams();
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);

  const intl = useIntl();

  const [
    {
      refetch: getCallRecordsList,
      isFetching: isCallRecordsLoading,
      isError: isCallRecordsError,
      data: callRecordsResp,
    },
    {
      refetch: getPatientData,
      isFetching: isPatientDataLoading,
      isError: isPatientDataError,
      data: patientDataResp,
    },
  ] = useQueries([
    {
      queryKey: 'get-call-records',
      queryFn: () => apiClient.getCallRecords(internalId!),
      enabled: false,
    },
    {
      queryKey: 'get-patient-data',
      queryFn: () => apiClient.getUserData(Number(userId), UserRole.patient),
      enabled: false,
    },
  ]);

  const callRecords = useMemo(() => {
    if (callRecordsResp) {
      if (isArray(callRecordsResp)) {
        return callRecordsResp;
      }
    }
    return [];
  }, [callRecordsResp]);

  const patientName = useMemo(() => {
    if (patientDataResp) {
      return patientDataResp.name;
    }
    return '';
  }, [patientDataResp]);

  const [NORECORDS, ERROR] = [
    I18nKey.NO_DATA_CALL_RECORDS,
    I18nKey.ERROR_MESSAGE,
  ].map(key => intl.formatMessage({ id: key }));

  useEffect(() => {
    getCallRecords();
    return () => {
      controller.abort();
    };
  }, []);

  const getCallRecords = () => {
    getCallRecordsList();
    getPatientData();
  };

  const formattedTime = (minutes: any) => {
    const fullMinutes = Math.floor(minutes);
    const seconds = Math.round((minutes - fullMinutes) * 60);
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${fullMinutes}:${formattedSeconds}`;
  };

  const prepareDataTableRows = () => {
    var groupedData = groupBy(
      callRecords,
      record =>
        new Date(record.date_p.replace(/-/g, '/')).getUTCMonth() +
        '.' +
        new Date(record.date_p.replace(/-/g, '/')).getFullYear(),
    );

    var sortedKeys = Object.keys(groupedData)
      .sort((x, y) => {
        var split_x = x.split('.');
        var split_y = y.split('.');

        if (split_x[1] !== split_y[1]) {
          return parseInt(split_x[1]) - parseInt(split_y[1]);
        }

        return parseInt(split_x[0]) - parseInt(split_y[0]);
      })
      .reverse();
    var rows: JSX.Element[] = [];

    sortedKeys.forEach(month => {
      Object.values(groupedData[month]).forEach(record => {
        rows.push(
          <div key={record.date_p + record.notes + record.call_duration}>
            <div className="recordHeader">
              <span>{convertUTCDateToLocal(record.date_p)}</span>
              <span>
                <b>{intl.formatMessage({ id: I18nKey.ENTERED_BY })}:</b>{' '}
                {record.prv_name}
              </span>
            </div>
            <div className="recordData">
              <div>
                <b>{intl.formatMessage({ id: I18nKey.DURATION })}:</b>{' '}
                {formattedTime(record.call_duration / 60)} min
              </div>
              <div>
                <b>{intl.formatMessage({ id: I18nKey.ITEMS_DISCUSSED })}:</b>
                <div dangerouslySetInnerHTML={{ __html: record.desc }} />
              </div>
              <br />
              <div>
                <div dangerouslySetInnerHTML={{ __html: record.notes }} />
              </div>
            </div>
          </div>,
        );
      });
    });

    return rows;
  };
  return (
    <div>
      <div className="textCenter printButtonMargin">
        <button onClick={() => window.print()}>
          {intl.formatMessage({ id: I18nKey.PRINT })}
        </button>
      </div>
      {isPatientDataError ? (
        <h3 className="textCenter red">Failed to load Patient Name</h3>
      ) : (
        <h1 className="textCenter">{patientName} </h1>
      )}

      {isCallRecordsLoading || isPatientDataLoading ? (
        <div className="callRecordsCenterLoading">
          <CircularProgress />
        </div>
      ) : isCallRecordsError ? (
        <div className="infoCard">
          <Alert severity="info">
            <AlertTitle>{intl.formatMessage({ id: I18nKey.INFO })}</AlertTitle>
            <h2>{ERROR}</h2>
          </Alert>
        </div>
      ) : isEmpty(callRecords) ? (
        <div className="infoCard">
          <Alert severity="info">
            <AlertTitle>{intl.formatMessage({ id: I18nKey.INFO })}</AlertTitle>
            <h2>{NORECORDS}</h2>
          </Alert>
        </div>
      ) : (
        prepareDataTableRows().map(component => component)
      )}
    </div>
  );
};

export default CallRecords;
